import {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, gql } from "@apollo/client";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import LeftArrow from "../../../assets/pngs/leftArrow.png";

const StyledPopOverBox = styled(Box)(() => ({
	display: "flex",
	border: "1px solid #025131",
	borderRadius: "8px",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	minWidth: "297px",
	gap: "20px",
}));

const PlaceHolderCategories = [
	{ key: "regulations", name: "FDA Regulations", count: 2 },
	{ key: "productCodes", name: "Product Code", count: 6 },
	{ key: "standards", name: "Standards", count: 3 },
	{ key: "guidances", name: "Guidance Docs.", count: 5 },
	{ key: "companies", name: "Companies w/ 510k ", count: 2 },
	{ key: "companiesW", name: "Companies w/ recalls ", count: 6 },
];

const CategoriesColumn = ({
	step,
	selectedCategory,
	handleSelectedCategory,
}) => {
	const dispatch = useDispatch();

	const selectedKeywords = useSelector(
		(state) => state?.explore?.selectedKeywords
	);
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const categoriesCountsInStore = useSelector(
		(state) => state?.explore?.categoryCounts
	);

	const [categoryCounts, setCategoryCounts] = useState(
		categoriesCountsInStore || null
	);
	const abortControllerRef = useRef(null);


	const getKeywordIds = () => {
		return selectedKeywords?.map((obj) => obj?.id);
	};

	const getCategoryCount = (category) => {
		const key = `${category?.key}Count`;
		return categoryCounts?.[key] || 0;
	};

	const GET_CATEGORIES_COUNTS_GQL = gql`
		query GetCategoryCounts($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					companiesCount
					guidancesCount
					productCodesCount
					regulationsCount
					standardsCount
				}
			}
		}
	`;

	const [fetchCategoryCount, { loading: categoryCountLoading }] = useLazyQuery(
		GET_CATEGORIES_COUNTS_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const handleGetCategoryCounts = () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}
		abortControllerRef.current = new AbortController();

		fetchCategoryCount({
			variables: {
				ideaId: ideaId,
				ids: getKeywordIds(),
			},
			context: {
				fetchOptions: {
					signal: abortControllerRef.current.signal,
				},
			},
		})
			.then((res) => {
				setCategoryCounts(res?.data?.idea?.suggestedKeywordsSummary);

				dispatch({
					type: "SET_CATEGORY_COUNTS_SUCCESS",
					payload: res?.data?.idea?.suggestedKeywordsSummary,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (step === 6 && selectedKeywords && selectedKeywords?.length !== 0) {
			handleGetCategoryCounts();
		}
	}, [selectedKeywords]);

	useEffect(() => {
		if (
			categoriesCountsInStore === null ||
			categoriesCountsInStore === undefined ||
			categoriesCountsInStore?.length === 0
		) {
			setCategoryCounts(null);
			handleSelectedCategory(null);
		} else {
			setCategoryCounts(categoriesCountsInStore);
		}
	}, [categoriesCountsInStore]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "13.8em",
				flexDirection: "column",
			}}
		>
			<motion.div
				initial={{ opacity: 0, display: "hidden" }}
				animate={step > 5 ? { opacity: 1, display: "visible" } : {}}
				transition={{ duration: 1 }}
			>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<img
						src={LeftArrow}
						alt=""
						style={{
							position: "relative",
							top: "70px",
							left: "-15px",
							width: "16px",
							height: "29px",
						}}
					/>

					<StyledPopOverBox style={{ height: "363px" }}>
						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
						>
							Categories
						</Typography>

						<Divider style={{ minWidth: "70%", backgroundColor: "#303030" }} />

						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "right",
								gap: "10px",
							}}
						>
							{categoryCountLoading ? (
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								PlaceHolderCategories?.map((category, key) => {
									return (
										<Box
											key={key}
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: "15px",
											}}
										>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													justifyContent: "right",
													cursor:
														key === 2 || key === 3 || key === 5
															? "default"
															: "pointer",
												}}
												onClick={() => {
													if (key === 2 || key === 3 || key === 5) {
													} else {
														handleSelectedCategory(category?.key);
														dispatch({
															type: "UPDATE_STEP",
															payload: 7,
														});
													}
												}}
											>
												<Typography
													fontWeight={"bold"}
													fontFamily={"Inter"}
													fontSize={"16px"}
													color={
														key === 2 || key === 3 || key === 5
															? "#D9D9D9"
															: "#303030"
													}
												>
													{category?.name}
												</Typography>

												<Box
													sx={{
														backgroundColor:
															selectedCategory === category?.key
																? "#025131"
																: key === 2 || key === 3 || key === 5
																? "#D9D9D9"
																: "#F2F2F2",
														color: "#F2F2F2",
														borderRadius: "20px",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														minWidth: 45,
														padding: "0px 10px",
														height: 30,
														marginLeft: 1,
														"&:hover": {
															cursor:
																key === 2 || key === 3 || key === 5
																	? "default"
																	: "pointer",
														},
													}}
												>
													<Typography
														fontWeight={"bold"}
														fontFamily={"Inter"}
														fontSize={"20px"}
														color={
															selectedCategory === category?.key
																? "#fff"
																: key === 2 || key === 3 || key === 5
																? "fff"
																: "#025131"
														}
													>
														{getCategoryCount(category)}
													</Typography>
												</Box>
											</Box>
											{key === 3 && (
												<Divider
													style={{
														minWidth: "70%",
														backgroundColor: "#303030",
													}}
												/>
											)}
										</Box>
									);
								})
							)}
						</Box>
					</StyledPopOverBox>
				</Box>
			</motion.div>
		</Box>
	);
};

export default CategoriesColumn;
