import { Grid2 as Grid, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_REGULATORY_AND_QUALITY_GLOSSARY_GQL } from "../../gql/queries/summary";
import { NotificationToaster } from "../../utils/NotificationToaster";

const RegulatoryQualityGlossary = (props) => {
    const { ideaId } = props;
    const [regulatoryAndQualityGlossaryDetail, setRegulatoryAndQualityGlossaryDetail] = useState({
        contraIndications: "",
        intendedUse: "",
        indicationsForUse: "",
        regulations: [],
        productCode: {
            code: "",
            deviceClassCode: ""
        }
    });

    const [fetchRegulatoryAndQualityGlossary, { loading }] = useLazyQuery(
        GET_REGULATORY_AND_QUALITY_GLOSSARY_GQL
    );

    const fetchGlossaryData = () => {
        fetchRegulatoryAndQualityGlossary({
            variables: {
                ideaId
            },
        })
            .then((response) => {
                const companiesResponse = response.data.idea ?? {};
                setRegulatoryAndQualityGlossaryDetail(prevState => ({
                    ...prevState,
                    ...companiesResponse
                }))

            })
            .catch((e) => {
                NotificationToaster({
                    message: e.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }


    useEffect(() => {
        fetchGlossaryData();
    }, []);

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid size={8}>
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Regulatory & Quality Glossary
                </Typography>
            </Grid>

            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container sx={{ marginLeft: "30px", marginTop: "10px" }} spacing={1}>
                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Product Code:
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>{regulatoryAndQualityGlossaryDetail.productCode?.code ?? "not defined yet"}</em>
                            </Typography>
                        </Grid>

                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Class:
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>{regulatoryAndQualityGlossaryDetail.productCode?.deviceClassCode ?? "not defined yet"}</em>
                            </Typography>
                        </Grid>

                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Product Regulation(s):
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>
                                    {
                                        regulatoryAndQualityGlossaryDetail?.regulations?.length > 0 ? (
                                            regulatoryAndQualityGlossaryDetail.regulations.map(r => r?.id)?.join(",")
                                        ) : (
                                            "not defined yet"
                                        )
                                    }
                                </em>
                            </Typography>
                        </Grid>

                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Indications for Use:
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>{regulatoryAndQualityGlossaryDetail?.indicationsForUse ?? "not defined yet"}</em>
                            </Typography>
                        </Grid>

                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Contraindications:
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>{regulatoryAndQualityGlossaryDetail?.contraIndications ?? "not defined yet"}</em>
                            </Typography>
                        </Grid>

                        <Grid size={3}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#303030"}
                            >
                                Intended Use:
                            </Typography>
                        </Grid>

                        <Grid size={9}>
                            <Typography
                                fontWeight={"light"}
                                fontFamily={"Inter"}
                                fontSize={"16px"}
                                color={"#C8C8C8"}
                            >
                                <em>{regulatoryAndQualityGlossaryDetail?.intendedUse ?? "not defined yet"}</em>
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}
export default RegulatoryQualityGlossary;