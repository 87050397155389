import {gql} from "@apollo/client";

export const TREE_GRAPH = gql`
    query Graph($ids: [ID!]!) {
        graph(ids: $ids) {
            links {
                source
                target
            }
            nodes {
                id
                type
                properties
            }
        }
    }
`;