import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Box, Typography, Grid2 as Grid, CircularProgress } from "@mui/material";
import { GET_STANDARDS_AND_GUIDELINES_GQL } from "../../gql/queries/summary";
import { NotificationToaster } from "../../utils/NotificationToaster";

const StandardsAndGuidelines = (props) => {
    const { ideaId } = props;

    const [standardsAndGuidelines, setStandardsAndGuidelines] = useState([]);

    const [fetchStandardsGuidelines, { loading }] = useLazyQuery(
        GET_STANDARDS_AND_GUIDELINES_GQL,
        {
            fetchPolicy: 'no-cache'
        }
    );

    const fetchStandardsAndGuidelinesData = () => {
        fetchStandardsGuidelines({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setStandardsAndGuidelines(res?.data?.idea);
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchStandardsAndGuidelinesData();
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Applicable Standards & Guidelines
                </Typography>
            </Box>

            {
                loading ? (
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid
                            size={12}
                            textAlign={"center"}
                            sx={{
                                height: '100%',
                                alignItems: "center"
                            }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    standardsAndGuidelines?.regulations?.length === 0 || standardsAndGuidelines?.standards?.length === 0 ? (
                        <Grid container mt={2} direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }}>
                            <Grid size={12} textAlign={"center"}>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container sx={{ marginLeft: "30px", marginTop: "10px", maxHeight: "14.5rem", overflowY: "auto" }}>
                            {
                                standardsAndGuidelines?.list?.map((item, idx) => {
                                    return (
                                        <Grid
                                            key={item?.id || idx + 1}
                                            size={12}
                                            spacing={0}
                                        >
                                            <Grid container>
                                                <Grid>
                                                    <Typography
                                                        fontWeight={500}
                                                        fontFamily={"Inter"}
                                                        fontSize={"16px"}
                                                        color={"#038C5A"}
                                                        display="inline"
                                                    >
                                                        Company #{idx + 1}:&nbsp;
                                                    </Typography>
                                                </Grid>
                                                <Grid size={"grow"}>
                                                    <Typography
                                                        fontWeight={"bold"}
                                                        fontFamily={"Inter"}
                                                        fontSize={"16px"}
                                                        color={"#038C5A"}
                                                        display="inline"
                                                        fontStyle={"italic"}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )
                )
            }
        </>
    )
}
export default StandardsAndGuidelines;