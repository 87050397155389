import React, { useEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import { Sankey } from '@amcharts/amcharts5/flow';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const SankeyChart = () => {
  const chartRef = useRef(null);
  const [customColors, setCustomColors] = useState({});

  useEffect(() => {
    const colors = {
      low: getComputedStyle(document.documentElement).getPropertyValue('--severity-low').trim(),
      medium: getComputedStyle(document.documentElement).getPropertyValue('--severity-medium').trim(),
      high: getComputedStyle(document.documentElement).getPropertyValue('--severity-high').trim(),
      critical: getComputedStyle(document.documentElement).getPropertyValue('--severity-critical').trim(),
    };
    setCustomColors(colors);

    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const series = root.container.children.push(Sankey.new(root, {
      sourceIdField: 'from',
      targetIdField: 'to',
      valueField: 'value',
      paddingRight: 29,
      paddingLeft: 0,
      nodePadding: 12,
    }));
    root._logo.dispose();

    const colorMap = {
      'Instruments & Accessories': { node: '#F79A0F', link: '#F79A0F80' },
      'Services': { node: '#F79A0F', link: '#F79A0F80' },
      'System': { node: '#1E5A94', link: '#1E5A9480' },
      'Recurring Revenue': { node: '#F79A0F', link: '#F79A0F80' },
      'Operating Lease': { node: '#F79A0F', link: '#F79A0F80' },
      'Capital Revenue': { node: '#1E5A94', link: '#1E5A9480' },
      'Total Revenue': { node: '#1E5A94', link: '#1E5A9480' },
      'Gross Profit': { node: '#038C5A', link: '#038C5A80' },
      'Operating Income': { node: '#038C5A', link: '#038C5A80' },
      'Interest': { node: '#038C5A', link: '#038C5A80' },
      'Pre-Tax Income': { node: '#038C5A', link: '#038C5A80' },
      'Net Income': { node: '#038C5A', link: '#038C5A80' },
      'COGS': { node: '#C42917', link: '#C4291780' },
      'Operating Expenses': { node: '#C42917', link: '#C4291780' },
      'Tax Expenses': { node: '#C42917', link: '#C4291780' },
      'SG&A Expenses': { node: '#C42917', link: '#C4291780' },
      'R&D Expenses': { node: '#C42917', link: '#C4291780' },
    };

    const formattedLabels = (name) => name.split(' ').join('\n');

    series.nodes.setAll({
      nameField: 'name',
    });

    // Create a node tooltip with fade-in and fade-out effects
    const nodeTooltip = am5.Tooltip.new(root, {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      pointerOrientation: 'horizontal',
    });

    nodeTooltip.label.setAll({
      text: '{name}',
      fontSize: 12,
      wrap: true,
      textAlign: 'center',
    });

    series.nodes.rectangles.template.setAll({
      tooltip: nodeTooltip,
      tooltipText: '{name}',
      fillGradient: undefined,
      fillOpacity: 1,
      strokeOpacity: 0,
      strokeWidth: 0,
      stroke: am5.color(0x00000000),
    });

    // Add smoother fade-in/out effect for the tooltip
    nodeTooltip.states.create('default', {
      animations: [
        { key: 'opacity', from: 0, to: 1, duration: 600, easing: am5.ease.out(am5.ease.cubic) },
      ],
    });

    nodeTooltip.states.create('hidden', {
      animations: [
        { key: 'opacity', from: 1, to: 0, duration: 600, easing: am5.ease.out(am5.ease.cubic) },
      ],
    });

    // Create a link tooltip with fade-in and fade-out effects
    const linkTooltip = am5.Tooltip.new(root, {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      pointerOrientation: 'horizontal',
    });

    linkTooltip.label.setAll({
      text: '{from} → {to}\nValue: {value}',
      fontSize: 12,
      wrap: true,
      textAlign: 'center',
    });

    series.links.template.setAll({
      tooltip: linkTooltip,
      tooltipText: '{from} → {to}\nValue: {value}',
      fillOpacity: 0.5,
      fillGradient: undefined,
      strokeOpacity: 0,
      strokeWidth: 0,
    });

    // Add smoother fade-in/out effect for the link tooltip
    linkTooltip.states.create('default', {
      animations: [
        { key: 'opacity', from: 0, to: 1, duration: 600, easing: am5.ease.out(am5.ease.cubic) },
      ],
    });

    linkTooltip.states.create('hidden', {
      animations: [
        { key: 'opacity', from: 1, to: 0, duration: 600, easing: am5.ease.out(am5.ease.cubic) },
      ],
    });

    // Define nodes data with custom positions
    series.nodes.data.setAll([
      { id: 'Instruments & Accessories', name: formattedLabels('Instruments & Accessories'), fill: am5.color(colorMap['Instruments & Accessories'].node), x: am5.percent(0), y: am5.percent(20) },
      { id: 'Services', name: formattedLabels('Services'), fill: am5.color(colorMap['Services'].node), x: am5.percent(0), y: am5.percent(40) },
      { id: 'System', name: formattedLabels('System'), fill: am5.color(colorMap['System'].node), x: am5.percent(0), y: am5.percent(60) },
      { id: 'Operating Lease', name: formattedLabels('Operating Lease'), fill: am5.color(colorMap['Operating Lease'].node), x: am5.percent(0), y: am5.percent(80) },
      { id: 'Recurring Revenue', name: formattedLabels('Recurring Revenue'), fill: am5.color(colorMap['Recurring Revenue'].node), x: am5.percent(20), y: am5.percent(30) },
      { id: 'Capital Revenue', name: formattedLabels('Capital Revenue'), fill: am5.color(colorMap['Capital Revenue'].node), x: am5.percent(20), y: am5.percent(50) },
      { id: 'Total Revenue', name: formattedLabels('Total Revenue'), fill: am5.color(colorMap['Total Revenue'].node), x: am5.percent(40), y: am5.percent(40) },
      { id: 'Gross Profit', name: formattedLabels('Gross Profit'), fill: am5.color(colorMap['Gross Profit'].node), x: am5.percent(60), y: am5.percent(30) },
      { id: 'Operating Income', name: formattedLabels('Operating Income'), fill: am5.color(colorMap['Operating Income'].node), x: am5.percent(80), y: am5.percent(20) },
      { id: 'Interest', name: formattedLabels('Interest'), fill: am5.color(colorMap['Interest'].node), x: am5.percent(100), y: am5.percent(10) },
      { id: 'Pre-Tax Income', name: formattedLabels('Pre-Tax Income'), fill: am5.color(colorMap['Pre-Tax Income'].node), x: am5.percent(80), y: am5.percent(30) },
      { id: 'Net Income', name: formattedLabels('Net Income'), fill: am5.color(colorMap['Net Income'].node), x: am5.percent(100), y: am5.percent(20) },
      { id: 'COGS', name: formattedLabels('COGS'), fill: am5.color(colorMap['COGS'].node), x: am5.percent(60), y: am5.percent(60) },
      { id: 'Operating Expenses', name: formattedLabels('Operating Expenses'), fill: am5.color(colorMap['Operating Expenses'].node), x: am5.percent(60), y: am5.percent(80) },
      { id: 'Tax Expenses', name: formattedLabels('Tax Expenses'), fill: am5.color(colorMap['Tax Expenses'].node), x: am5.percent(80), y: am5.percent(70) },
      { id: 'SG&A Expenses', name: formattedLabels('SG&A Expenses'), fill: am5.color(colorMap['SG&A Expenses'].node), x: am5.percent(80), y: am5.percent(80) },
      { id: 'R&D Expenses', name: formattedLabels('R&D Expenses'), fill: am5.color(colorMap['R&D Expenses'].node), x: am5.percent(80), y: am5.percent(90) },
    ]);

    // Define links data
    series.data.setAll([
      { from: 'Instruments & Accessories', to: 'Recurring Revenue', value: 985.6, fill: am5.color(colorMap['Instruments & Accessories'].link) },
      { from: 'Services', to: 'Recurring Revenue', value: 283.2, fill: am5.color(colorMap['Services'].link) },
      { from: 'System', to: 'Capital Revenue', value: 427.4, fill: am5.color(colorMap['System'].link) },
      { from: 'Operating Lease', to: 'Recurring Revenue', value: 112, fill: am5.color(colorMap['Operating Lease'].link) },
      { from: 'Recurring Revenue', to: 'Total Revenue', value: 1380.8, fill: am5.color(colorMap['Recurring Revenue'].link) },
      { from: 'Capital Revenue', to: 'Total Revenue', value: 315.4, fill: am5.color(colorMap['Capital Revenue'].link) },
      { from: 'Total Revenue', to: 'Gross Profit', value: 1113, fill: am5.color(colorMap['Total Revenue'].link) },
      { from: 'Gross Profit', to: 'Operating Income', value: 387.6, fill: am5.color(colorMap['Gross Profit'].link) },
      { from: 'Operating Income', to: 'Interest', value: 134.2, fill: am5.color(colorMap['Interest'].link) },
      { from: 'Operating Income', to: 'Pre-Tax Income', value: 421.8, fill: am5.color(colorMap['Operating Income'].link) },
      { from: 'Pre-Tax Income', to: 'Net Income', value: 360.8, fill: am5.color(colorMap['Pre-Tax Income'].link) },
      { from: 'Gross Profit', to: 'COGS', value: 583.2, fill: am5.color(colorMap['COGS'].link) },
      { from: 'Gross Profit', to: 'Operating Expenses', value: 725.4, fill: am5.color(colorMap['Operating Expenses'].link) },
      { from: 'Operating Expenses', to: 'Tax Expenses', value: 61, fill: am5.color(colorMap['Tax Expenses'].link) },
      { from: 'Operating Expenses', to: 'SG&A Expenses', value: 480.5, fill: am5.color(colorMap['SG&A Expenses'].link) },
      { from: 'Operating Expenses', to: 'R&D Expenses', value: 244.9, fill: am5.color(colorMap['R&D Expenses'].link) },
    ]);

    series.nodes.labels.template.setAll({
      fontSize: 5,
      fill: am5.color(0x000000),
      oversizedBehavior: 'wrap',
      maxWidth: 150,
      position: 'right',
      dx: 10,
    });

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflow: 'visible !important',
      }}
    >
      <div ref={chartRef} style={{ width: '250px', height: '130px', overflow: 'visible !important' }} />
    </div>
  );
};

export default SankeyChart;
