import { Button, CircularProgress, Grid2 as Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { NotificationToaster } from "../../utils/NotificationToaster";
import { GET_PREDICATE_DEVICES_GQL } from "../../gql/queries/summary";
import { useLazyQuery } from "@apollo/client";

const HeaderCell = styled(Grid)(() => ({
    backgroundColor: "#F2F2F2",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "10px",
    textAlign: "center",
    width: "120px",
    minWidth: "12em",
    padding: 0,
    height: "30px",
    border: "1px solid #888",
}));

const StatusButton = styled(Button)({
    backgroundColor: "#F0C577",
    color: "#fff",
    textTransform: "none",
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "italic",
    borderRadius: "15px",
    height: "15px",
    width: "90px",
    padding: 0,
});

const StatusCell = styled(Grid)({
    backgroundColor: "#C0E2D6",
    fontWeight: "bold",
    fontFamily: "Inter",
    fontSize: "10px",
    textAlign: "center",
    width: "120px",
    minWidth: "12em",
    padding: 0,
    height: "30px",
    border: "1px solid #888",
});

const StyledTableCell = styled(Grid)({
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "italic",
    fontSize: "10px",
    width: "120px",
    minWidth: "12em",
    padding: 0,
    height: "30px",
    border: "0.2px solid #888",
    lineHeight: 3
});

const PredicateDevicesTable = (props) => {
    const { ideaId } = props;

    const [predicateDevices, setPredicateDevices] = useState([]);

    const handleRemovePredicateDevice = (row) => {
        setPredicateDevices((prevState) => prevState.devices.filter(item => item.deviceId !== row.deviceId));
    }

    const [fetchPredicateDevices, { loading }] = useLazyQuery(
        GET_PREDICATE_DEVICES_GQL, {
        fetchPolicy: 'no-cache'
    }
    );

    const fetchPredicateDevicesData = () => {
        fetchPredicateDevices({
            variables: {
                ideaId
            },
        })
            .then((res) => {
                setPredicateDevices(res?.data?.idea?.products);
            })
            .catch((err) => {
                console.log(err);
                NotificationToaster({
                    message: err.message ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        fetchPredicateDevicesData();
    }, []);

    return (
        <Grid container direction={"column"} spacing={2}>
            <Grid>
                <Typography
                    fontWeight={"bold"}
                    fontFamily={"Inter"}
                    fontSize={"16px"}
                    color={"#303030"}
                >
                    Predicate Devices
                </Typography>
            </Grid>
            {
                loading ? (
                    <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Grid>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    predicateDevices?.length === 0 ? (
                        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: '100%', maxHeight: "16em" }}>
                            <Grid>
                                No Records Found
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction={"row"} wrap={"nowrap"} sx={{ overflowX: "auto", overflowY: "hide", maxWidth: "98%", minHeight: "15rem", maxHeight: "16em" }} spacing={0}>
                            <Grid container direction={"column"} sx={{ pt: 4.9, ml: 4, minWidth: "7.5em" }}>
                                <HeaderCell >Device ID</HeaderCell>
                                <HeaderCell >Company Name</HeaderCell>
                                <HeaderCell >Device Name</HeaderCell>
                                <HeaderCell >Product Code</HeaderCell>
                                <HeaderCell >Device Class</HeaderCell>
                                <StatusCell >Status</StatusCell>
                            </Grid>
                            {
                                predicateDevices?.map((item, idx) => {
                                    return (
                                        <Grid container direction={"column"} spacing={0} sx={{ minWidth: "7.5em" }}>
                                            <Grid size={3} onClick={() => handleRemovePredicateDevice(item)} sx={{
                                                p: 0.5,
                                                ml: 6,
                                                mb: 1.5,
                                                backgroundColor: "#D9D9D9",
                                                textAlign: "center",
                                                fontWeight: "900",
                                                fontFamily: "Inter",
                                                cursor: "pointer"
                                            }}>
                                                X
                                            </Grid>
                                            <StyledTableCell align="center">{item.deviceId}</StyledTableCell>
                                            <StyledTableCell align="center">{item.companyName}</StyledTableCell>
                                            <StyledTableCell align="center">{item.deviceName}</StyledTableCell>
                                            <StyledTableCell align="center">{item.productCode}</StyledTableCell>
                                            <StyledTableCell align="center">{item.deviceClass}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <StatusButton variant="contained">New Changes</StatusButton>
                                            </StyledTableCell>
                                        </Grid>
                                    )
                                })
                            }

                        </Grid>
                    )
                )
            }


            {/*<TableContainer*/}
            {/*	component={Paper}*/}
            {/*	elevation={0}*/}
            {/*	style={{ margin: "20px auto", maxWidth: "480px" }}*/}
            {/*>*/}
            {/*	<Table>*/}
            {/*		<TableHead>*/}
            {/*			<TableRow>*/}
            {/*				<HeaderCell>Device ID</HeaderCell>*/}
            {/*				<StyledTableCell align="center">K192898</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">DEN170075</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">K10001</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*		</TableHead>*/}
            {/*		<TableBody>*/}
            {/*			<TableRow>*/}
            {/*				<HeaderCell>Company Name</HeaderCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					Advanced Surgical Concepts Ltd.*/}
            {/*				</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					Advanced Surgical Concepts Ltd.*/}
            {/*				</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					Agency For Medical Innovations GmbH*/}
            {/*				</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*			<TableRow>*/}
            {/*				<HeaderCell>Device Name</HeaderCell>*/}
            {/*				<StyledTableCell align="center">PneumoLiner</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">ContainOR</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">Device #3</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*			<TableRow>*/}
            {/*				<HeaderCell>Product Code</HeaderCell>*/}
            {/*				<StyledTableCell align="center">PMU</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">PZQ</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">PZQ</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*			<TableRow>*/}
            {/*				<HeaderCell>Device Class</HeaderCell>*/}
            {/*				<StyledTableCell align="center">2</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">2</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">2</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*			<TableRow>*/}
            {/*				<StatusCell>Status</StatusCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					<StatusButton variant="contained">New Changes</StatusButton>*/}
            {/*				</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					<StatusButton variant="contained">New Changes</StatusButton>*/}
            {/*				</StyledTableCell>*/}
            {/*				<StyledTableCell align="center">*/}
            {/*					<StatusButton variant="contained">New Changes</StatusButton>*/}
            {/*				</StyledTableCell>*/}
            {/*			</TableRow>*/}
            {/*		</TableBody>*/}
            {/*	</Table>*/}
            {/*</TableContainer>*/}

        </Grid>
    )
}
export default PredicateDevicesTable;