import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../components/shared/Navbar';
import Sidebar from '../components/shared/Sidebar';
import { useShowViewToggle } from '../hooks/useShowViewToggle';
import { ToggleProvider } from '../contexts/ToggleContext';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import ConfirmationBubble from '../components/TracerRequirements/ConfirmationBubble/ConfirmationBubble';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Material-UI styled components
const Wrapper = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: "hidden"
});

const NavbarWrapper = styled(Box)(({ showModal }) => ({
  position: 'relative',
  zIndex: showModal ? 0 : 2, // Navbar is under the Backdrop when modal is open
  width: '100%', // Navbar takes the full width
  height: '100px', // Define the height of the Navbar
}));

const LayoutWrapper = styled(Box)({
  display: 'flex',
  flex: 1, // Use flex to make Sidebar and Content side-by-side
  position: 'relative',
  zIndex: 1, // Sidebar and main content should be under the Navbar when modal is not open
});

const SidebarWrapper = styled(Box)(({ showModal }) => ({
  width: '250px', // Adjust width based on your Sidebar component
  zIndex: showModal ? 0 : 1, // Sidebar under the Navbar when modal is open
  flexShrink: 0, // Prevent sidebar from shrinking
  height: 'calc(100vh - 100px)', // Sidebar fills the remaining height after the Navbar
}));

const ContentWrapper = styled(Box)(({ step, path }) => ({
  display: 'flex',
  flex: 1,
  overflowY: step < 5 && path.includes("Explore") ? (
    'hidden'
  ) : (
    'auto'
  ),
  overflowX: step < 5 && path.includes("Explore") ? (
    'hidden'
  ) : (
    'auto'
  ),
  height: "calc(100vh - 6.3rem)",
  position: 'relative',
  padding: '0 16px',
}));

const MainContent = styled(Box)({
  flex: 1,
  display: 'flex',
  position: 'relative',
  zIndex: 1, // Ensure content is above the Sidebar but below the modal,
  height: "calc(100vh + 14rem)"
});

const ModalOverlay = styled(motion.div)({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '50%',
  backgroundColor: 'white', // Ensure modal is fully visible
  zIndex: 1200, // Ensure modal is above the backdrop
  border: '3px solid #038C5A',
  maxWidth: '1470px',
  borderRadius: '10px 10px 0 0',
});

const MotionBackdrop = styled(motion.div)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black backdrop
  zIndex: 1000, // Ensure the backdrop is above everything except the modal
});

const CloseButton = styled(Button)({
  background: 'red',
  color: 'white',
  border: 'none',
  padding: '10px',
  cursor: 'pointer',
  position: 'absolute',
  top: '10px',
  right: '10px',
});

const MainLayout = ({ children }) => {
  const location = useLocation();
  const step = useSelector((state) => state?.explore?.step);
  const scrollContainerRef = useRef(null);

  const showViewToggle = useShowViewToggle();
  const [showDashedLines, setShowDashedLines] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  // Function to open the modal
  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const handleBackdropClick = (e) => {
    closeModal();
  };

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent click on modal from closing it
  };

  const [activeView, setActiveView] = useState('requirements');

  const smoothScroll = (targetPosition) => {
    const element = scrollContainerRef.current;
    const startPosition = element.scrollLeft;
    const distance = targetPosition - startPosition;
    const duration = 2000;
    let startTime = null;

    const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    const animation = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      const easeProgress = easeInOutQuad(progress);

      element.scrollLeft = startPosition + distance * easeProgress;

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  useEffect(() => {
    if (scrollContainerRef.current && step >= 6) {

      const newScrollPosition = step * (step === 6 ? 0 : step === 7 ? 150 : 100);
      smoothScroll(newScrollPosition);
    }

    if (scrollContainerRef.current && step === 0) {
      smoothScroll(-150);
    }
  }, [step]);

  return (
    <ToggleProvider value={{ showDashedLines, setShowDashedLines }}>
      {/* Wrapper for the entire layout */}
      <Wrapper>
        {/* Navbar */}
        <NavbarWrapper showModal={showModal}>
          <Navbar showViewToggle={showViewToggle} onViewChange={setActiveView} activeView={activeView} />
        </NavbarWrapper>

        {/* Layout wrapper with Sidebar and Content side-by-side */}
        <LayoutWrapper>
          {/* Sidebar */}
          <SidebarWrapper showModal={showModal}>
            <Sidebar />
          </SidebarWrapper>

          {/* Main content area */}
          <ContentWrapper ref={scrollContainerRef} step={step} path={location.pathname} className={"content-wrapper"}>
            <MainContent className={"main-content-wrapper"}>
              {/* Pass activeView and openModal to children */}
              {React.cloneElement(children, { activeView, openModal })}

              {/* Modal handling */}
              <AnimatePresence>
                {showModal && (
                  <>
                    {/* Backdrop that closes modal when clicked */}
                    <MotionBackdrop
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      onClick={handleBackdropClick}
                    />

                    {/* Modal that appears above the backdrop */}
                    <ModalOverlay
                      initial={{ opacity: 0, y: '100%' }}
                      animate={{ opacity: 1, y: '0%' }}
                      exit={{ opacity: 0, y: '100%' }}
                      transition={{ duration: 0.3 }}
                      onClick={handleModalClick} // Prevent click on modal from closing it
                    >
                      <ConfirmationBubble />
                    </ModalOverlay>
                  </>
                )}
              </AnimatePresence>
            </MainContent>
          </ContentWrapper>
        </LayoutWrapper>

        <ToastContainer position={"top-right"} autoClose={3000} />
      </Wrapper>
    </ToggleProvider>
  );
};

export default MainLayout;
