import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Box,
	Dialog,
	CircularProgress,
	Grid2 as Grid,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
} from "@mui/material";
import CloseButton from "../../../assets/pngs/closeButton.png";
import { styled } from "@mui/material/styles";
import ProductImage from "../../../assets/placeholders/product.png";
import AddToSummaryButton from "../../shared/AddToSummaryButton";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { ADD_PRODUCT_TO_IDEA } from "../../../gql/mutations/products";
import { NotificationToaster } from "../../../utils/NotificationToaster";
import { GET_IDEA_REGULATORY_AND_QUALITY_GLOSSARY } from "../../../gql/queries/summary";
import { IS_PRODUCT_ADDED_TO_IDEA } from "../../../gql/queries/products";
import GoToSummaryButton from "../../shared/GoToSummaryButton";

const TableOnePlaceholder = [
	{ title: "510(k) clearance", value: "K192898" },
	{ title: "Company Name", value: "Advanced Surgical Concepts Ltd." },
	{ title: "Device Name", value: "PneumoLiner" },
	{ title: "Device Class", value: "2" },
	{ title: "Product Code", value: "PMU" },
	{ title: "Regulation Number", value: "884.4050" },
	{ title: "FDA Clearance Date", value: "02/25/2020" },
	{ title: "Main Material", value: "Polyurethane" },
	{
		title: "Compatibility",
		value: "bipolar or electromechanical laparoscopic power morcellators",
	},
	{ title: "Shaft working length", value: "135mm to 180mm" },
	{ title: "Shaft outer diameter", value: "15mm to 18mm" },
];

const TableTwoPlaceholder = [
	{
		title: "Retractor Sleeve",
		value: 'Stevens ST625 FS 0.005" (Polyurethan film)',
	},
	{ title: "Sleeve Lubricant", value: "Pionier 2076P (Medicinal White Oil)" },
	{ title: "Distal Ring", value: "Pellethane 2363-55D (Polyurethan)" },
	{ title: "Outer Proximal Ring", value: "Makrolon RX-2530 1118 Tint (PC)" },
	{
		title: "Removal Ribbon",
		value: "70D teffeta Double Laminated (Nylon & TPU)",
	},
	{ title: "Inner Proximal Ring", value: "Isoplast 2530 (Polyurethane)" },
];

const TableThreePlaceholder = [
	{ title: "Boot", value: "Colorite 6148GTX-02 (PVC)" },
	{ title: "Large Valve Upper Housing", value: "Apec 1745 (PC)" },
	{ title: "Large Valve Lower Substrate", value: "Apec 1745 (PC)" },
	{
		title: "Large Valve Lipseal",
		value:
			"Shin-Etsu KE2090-40 (Silicone) Silcopas Yellow 90833 (Yellow colourant)",
	},
	{ title: "5mm Valve Housing", value: "Makrolon RX-2530 1118 Tint (PC)" },
	{
		title: "5mm Valve Cap",
		value: "Hampton HCM 26668PC (Blue colourant for Makrolon RX-2530)",
	},
	{ title: "5mm Crosscut 5mm Lipseal", value: "Wacker 3003/60 (Silicone)" },
	{ title: "Valve Lubricant", value: "Nusil MED-420 (Silicone Lubricant)" },
];

const TableFourPlaceholder = [
	{ title: "Introducer Shaft", value: "Makrolon RX-2530 1118 Tint (PC)" },
	{
		title: "Introducer Plunger",
		value: "Purell GC7260 (HDPE) Hampton HCM5285P (Blue colourant)",
	},
];

const StyledWhiteBox = styled(Box)(() => ({
	display: "flex",
	gap: "30px",
	padding: "30px 0px",
	flexDirection: "column",
	backgroundColor: "#fff",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "4px",
	border: "1px solid #626262",
	minWidth: "268px",
}));

const StyledTableComponent = ({ tableData }) => {
	return (
		<TableContainer
			sx={{
				borderRadius: "4px",
				border: "1px solid #000",
			}}
		>
			<Table
				sx={{
					borderCollapse: "collapse",
					borderStyle: "hidden",
					"& td": {
						border: "1px solid #303030",
					},
				}}
			>
				<TableBody>
					{tableData?.map((row, key) => {
						return (
							<TableRow key={key} style={{ height: "30px" }}>
								<TableCell
									align={"center"}
									style={{ width: "120px", padding: 0 }}
								>
									<Typography
										fontWeight={"bold"}
										fontFamily={"Inter"}
										fontSize={"10px"}
										color={"#303030"}
										style={{ textAlign: "center" }}
									>
										{row?.title}
									</Typography>
								</TableCell>

								<TableCell
									align={"center"}
									style={{ width: "160px", padding: "0px 20px" }}
								>
									<Typography
										lineHeight={"14px"}
										fontWeight={500}
										fontFamily={"Inter"}
										fontSize={"10px"}
										color={"#038C5A"}
										style={{ textAlign: "center" }}
									>
										<em>{row?.value}</em>
									</Typography>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const ProductDialog = ({
	productData,
	open,
	handleClose,
	handleViewSummary,
}) => {
	const ideaId = useSelector((state) => state?.explore?.idea?.id);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [isProductAdded, setIsProductAdded] = useState(false);
	const [productSpecs, setProductSpecs] = useState(null);

	const [addProductToIdea, { loading }] = useMutation(ADD_PRODUCT_TO_IDEA);
	const [IsProductAddedToIdea, { loading: isProductAddedLoading }] =
		useLazyQuery(IS_PRODUCT_ADDED_TO_IDEA);

	const GET_PRODUCT_SPECS_GQL = gql`
		query ProductSpecs($productId: ID!) {
			product(id: $productId) {
				specs {
					name
					value
				}
			}
		}
	`;

	const [getProductSpecs, { loading: getProductSpecsLoading }] = useLazyQuery(
		GET_PRODUCT_SPECS_GQL
	);

	const handleAddToSummary = () => {
		if (selectedProduct?.id) {
			if (!isProductAddedLoading) {
				addProductToIdea({
					variables: {
						ideaId,
						productId: selectedProduct?.id,
					},
				})
					.then(() => {
						setIsProductAdded(true);

						NotificationToaster({
							message: "Product added to summary",
							type: "success",
						});
					})
					.catch((e) => {
						NotificationToaster({
							message: e.message ?? "Something went wrong",
							type: "error",
						});
					});
			}
		} else {
			NotificationToaster({
				message: "Please select product",
				type: "info",
			});
		}
	};

	const checkIfProductAddedToIdea = () => {
		IsProductAddedToIdea({
			variables: {
				ideaId,
			},
		})
			.then((response) => {
				const addedProducts = response?.data?.idea?.products;
				if (
					addedProducts?.length > 0 &&
					addedProducts.some((product) => product?.id === selectedProduct?.id)
				) {
					setIsProductAdded(true);
				} else {
					setIsProductAdded(false);
				}
			})
			.catch((e) => {
				NotificationToaster({
					message: e.message ?? "Something went wrong",
					type: "error",
				});
			});
	};

	const getProductSpecsData = (productId) => {
		getProductSpecs({
			variables: { productId: productId },
		})
			.then((res) => {
				setProductSpecs(res?.data?.product?.specs);
			})
			.catch((err) => {
				NotificationToaster({
					message: "Error getting product specs",
					type: "error",
				});

				console.log(err);
			});
	};

	useEffect(() => {
		setSelectedProduct(productData?.product?.products[0]);
		checkIfProductAddedToIdea();
		getProductSpecsData(productData?.product?.products[0]?.id);
	}, [productData]);

	useEffect(() => {
		checkIfProductAddedToIdea();
		getProductSpecsData(selectedProduct?.id);
	}, [selectedProduct]);

	return (
		<Dialog
			onClose={() => {
				setIsProductAdded(false);
				handleClose();
			}}
			open={open}
			fullScreen
			disablePortal
			hideBackdrop
			PaperProps={{
				sx: {
					width: "70%",
					height: "80%",
					backgroundColor: "#F2F2F2",
					borderRadius: "4px",
					marginTop: "80px",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					padding: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<Box>
						{/* <Typography
							display="inline"
							color="#025131"
							fontSize={"40px"}
							fontFamily={"Inter"}
							fontWeight={"bold"}
						>
							Product #{productData?.id}: &nbsp;
						</Typography> */}

						<Typography
							display="inline"
							color="#038C5A"
							fontSize={"40px"}
							fontFamily={"Inter"}
							fontWeight={"bold"}
						>
							<em>{selectedProduct?.name}</em>
						</Typography>
					</Box>

					<img
						src={CloseButton}
						alt="x"
						width="30px"
						height="30px"
						style={{
							marginRight: 15,
							cursor: "pointer",
						}}
						onClick={handleClose}
					/>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					padding: 2,
					height: "100%",
				}}
			>
				<Grid
					container
					spacing={4}
					style={{
						height: "100%",
						marginLeft: 50,
					}}
				>
					<Grid item>
						<StyledWhiteBox>
							<Typography
								fontWeight={"bold"}
								fontFamily={"Inter"}
								fontSize={"20px"}
								color={"#303030"}
								style={{ textAlign: "center" }}
							>
								Product Portfolio
							</Typography>

							{productData?.product?.products?.map((product, key) => {
								return (
									<Box
										key={key}
										sx={{
											width: "210px",
											minHeight: "53px",
											backgroundColor:
												product === selectedProduct ? "#025131" : "transparent",
											borderRadius: "11px",
											border:
												product === selectedProduct
													? "1px solid #025131"
													: "1px solid #000",
											"&:hover": {
												backgroundColor:
													product === selectedProduct ? "#025131" : "#F2F2F2",
												color: product === selectedProduct ? "#fff" : "#000",
											},
											textTransform: "none",
											padding: 2,
										}}
										onClick={() => setSelectedProduct(product)}
									>
										<Typography
											display="inline"
											fontWeight={400}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={product === selectedProduct ? "#fff" : "#303030"}
										>
											Product {key + 1}:&nbsp;
										</Typography>

										<Typography
											display="inline"
											fontWeight={"bold"}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={product === selectedProduct ? "#fff" : "#303030"}
										>
											<em>{product?.name}</em>
										</Typography>
									</Box>
								);
							})}
						</StyledWhiteBox>
						<AddToSummaryButton
							isAdded={isProductAdded}
							onClick={handleAddToSummary}
						/>

						<GoToSummaryButton handleViewSummary={handleViewSummary} />
					</Grid>

					<Grid item size={{ xs: 8 }}>
						<StyledWhiteBox style={{ minWidth: "680px" }}>
							<Typography
								fontWeight={"bold"}
								fontFamily={"Inter"}
								fontSize={"20px"}
								color={"#303030"}
								style={{ textAlign: "center" }}
							>
								Product Specs
							</Typography>

							{getProductSpecsLoading ? (
								<Grid
									container
									direction={"column"}
									justifyContent={"center"}
									alignItems={"center"}
									sx={{
										width: "100%",
										height: "100%",
									}}
								>
									<Grid
										size={12}
										textAlign={"center"}
										sx={{
											height: "100%",
											alignItems: "center",
										}}
									>
										<CircularProgress />
									</Grid>
								</Grid>
							) : productSpecs?.length === 0 ||
							  productSpecs === null ||
							  productSpecs === undefined ? (
								<Grid
									container
									direction={"column"}
									justifyContent={"center"}
									alignItems={"center"}
									sx={{ width: "100%", height: "100%" }}
								>
									<Grid size={12} textAlign={"center"}>
										No Product Specs Found
									</Grid>
								</Grid>
							) : (
								<Grid
									container
									spacing={6}
									style={{
										height: "100%",
										padding: "0px 25px",
									}}
								>
									<Grid item>
										<Typography
											fontWeight={500}
											fontFamily={"Inter"}
											fontSize={"20px"}
											color={"#303030"}
											mb={1}
										>
											Key Facts
										</Typography>

										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												gap: "8px",
											}}
										>
											{/* <StyledTableComponent tableData={TableOnePlaceholder} /> */}

											{/* <img
												src={ProductImage}
												alt=""
												width={"320px"}
												height={"191px"}
											/> */}
										</Box>
									</Grid>

									<Grid item>
										<Typography
											fontWeight={500}
											fontFamily={"Inter"}
											fontSize={"20px"}
											color={"#303030"}
											mb={1}
										>
											Material
										</Typography>

										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												gap: "8px",
											}}
										>
											{/* <StyledTableComponent tableData={TableTwoPlaceholder} /> */}

											{/*<StyledTableComponent tableData={TableThreePlaceholder} />
	
											<StyledTableComponent tableData={TableFourPlaceholder} /> */}
										</Box>
									</Grid>
								</Grid>
							)}
						</StyledWhiteBox>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

export default ProductDialog;
