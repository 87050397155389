import {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StyledButton = styled(Button)(({ isSelected }) => ({
	height: "44px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected ? "1px solid #025131" : "1px solid #000",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const ListingColumn = ({
	step,
	selectedCategory,
	handleSelectedCategory,
	handleProductDialogOpen,
	handleFdaDialogOpen,
	handleCompanyDialogOpen,
}) => {
	const dispatch = useDispatch();

	const selectedKeywords = useSelector(
		(state) => state?.explore?.selectedKeywords
	);
	const ideaId = useSelector((state) => state?.explore?.idea?.id);

	const [categoryData, setCategoryData] = useState([]);
	const abortControllerRef = useRef(null);

	const getKeywordIds = () => {
		return selectedKeywords?.map((obj) => obj?.id);
	};

	const removeHtmlTags = (text) => {
		return text?.replace(/<.*?>/g, "");
	};

	const GET_REGULATION_DATA_GQL = gql`
		query GetRegulationData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					regulations {
						id
						title
						excerpt
						content {
							html
							suggestions {
								className
								color
								count
								name
							}
						}
					}
				}
			}
		}
	`;

	const [getRegulationData, { loading: getRegulationLoading }] = useLazyQuery(
		GET_REGULATION_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const GET_PRODUCT_DATA_GQL = gql`
		query GetProductCodeData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					productCodes {
						code
						deviceClassCode
						deviceName
						products {
							id
							name
						}
					}
				}
			}
		}
	`;

	const [getProductData, { loading: getProductCodeLoading }] = useLazyQuery(
		GET_PRODUCT_DATA_GQL,
		{
			fetchPolicy: "no-cache",
		}
	);

	const GET_COMPANIES_DATA_GQL = gql`
		query GetCompaniesData($ideaId: ID!, $ids: [ID!]) {
			idea(id: $ideaId) {
				suggestedKeywordsSummary(ids: $ids) {
					companies {
						id
						name
						graphId
						products {
							id
							name
						}
					}
				}
			}
		}
	`;

	const [getCompaniesData, { loading: getCompaniesLoading }] = useLazyQuery(
		GET_COMPANIES_DATA_GQL,
		{ fetchPolicy: "no-cache" }
	);

	const fetchData = (query, variables) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();

		return query({
			variables,
			context: {
				fetchOptions: {
					signal: abortControllerRef.current.signal,
				},
			},
		})
			.then((res) => {
				setCategoryData(res?.data?.idea?.suggestedKeywordsSummary);
			})
			.catch((err) => {
				if (err.name === "AbortError") {
					console.log("Fetch aborted");
				} else {
					console.log(err);
				}
			});
	};

	useEffect(() => {
		if (selectedCategory) {
			if (selectedCategory === "regulations") {
				fetchData(getRegulationData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "productCodes") {
				fetchData(getProductData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			} else if (selectedCategory === "companies") {
				fetchData(getCompaniesData, {
					ideaId: ideaId,
					ids: getKeywordIds(),
				});
			}
		}

		return () => {
			abortControllerRef.current = null;
		}
	}, [selectedCategory]);

	return (
		<Box
			sx={{
				display: "flex",
				marginTop: "2em",
				flexDirection: "column",
			}}
		>
			{getRegulationLoading || getProductCodeLoading || getCompaniesLoading ? (
				<Box
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "calc(100vh - 200px)",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<motion.div
					initial={{ opacity: 0, display: "hidden" }}
					animate={step > 6 ? { opacity: 1, display: "visible" } : {}}
					transition={{ duration: 1 }}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							gap: "10px",
						}}
					>
						<StyledButton
							isSelected
							style={{ width: "110px" }}
							onClick={() => {
								handleSelectedCategory(null);
								dispatch({
									type: "UPDATE_STEP",
									payload: 6,
								});
							}}
						>
							<ArrowBackIcon style={{ marginRight: 5 }} />
							Back
						</StyledButton>

						<Typography
							fontWeight={"bold"}
							fontFamily={"Inter"}
							fontSize={"20px"}
							color={"#303030"}
						>
							{selectedCategory === "regulations" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>FDA</span>{" "}
									Regulations
								</>
							) : selectedCategory === "productCodes" ? (
								<>
									Suggested <span style={{ color: "#038C5A" }}>Product</span>{" "}
									Codes
								</>
							) : (
								<>
									Suggested <span style={{ color: "#038C5A" }}>Companies</span>{" "}
									with <span style={{ color: "#038C5A" }}> 510(k)</span>
								</>
							)}
						</Typography>

						{selectedCategory === "regulations" ? (
							categoryData?.regulations?.map((regulation, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleFdaDialogOpen({
												id: key + 1,
												regulation: regulation,
											});
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#4BAAC8"}
										>
											{regulation?.id} : {removeHtmlTags(regulation?.title)}
										</Typography>

										<Typography
											fontWeight={300}
											fontFamily={"Inter"}
											fontSize={"12px"}
											color={"#303030"}
										>
											{removeHtmlTags(regulation?.excerpt)}
										</Typography>
									</Box>
								);
							})
						) : selectedCategory === "productCodes" ? (
							categoryData?.productCodes?.map((pCode, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleProductDialogOpen({ id: key + 1, product: pCode });
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#038C5A"}
										>
											<em>{pCode?.code}</em>
										</Typography>

										<Typography
											fontWeight={300}
											fontFamily={"Inter"}
											fontSize={"12px"}
											color={"#303030"}
										>
											{pCode?.productCode}
										</Typography>
									</Box>
								);
							})
						) : selectedCategory === "companies" ? (
							categoryData?.companies?.slice(0, 10)?.map((company, key) => {
								return (
									<Box
										key={key}
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "10px",
											cursor: "pointer",
										}}
										onClick={() => {
											handleCompanyDialogOpen({
												id: key + 1,
												company: company,
											});
										}}
									>
										<Typography
											fontWeight={600}
											fontFamily={"Inter"}
											fontSize={"16px"}
											color={"#038C5A"}
										>
											<em>{company?.name}</em>
										</Typography>

										{company?.products?.map((product, key) => {
											return (
												<>
													<Box key={key}>
														<Typography
															fontWeight={"light"}
															fontFamily={"Inter"}
															fontSize={"14px"}
															color={"#303030"}
															display="inline"
														>
															Product #{key + 1}:&nbsp;
														</Typography>

														<Typography
															fontFamily={"Inter"}
															fontSize={"14px"}
															color={"#303030"}
															display="inline"
														>
															{product?.name}
														</Typography>
													</Box>
												</>
											);
										})}
									</Box>
								);
							})
						) : (
							<></>
						)}
					</Box>
				</motion.div>
			)}
		</Box>
	);
};

export default ListingColumn;
