import { useState } from "react";
import {
	Box,
	Typography,
	Grid,
	Button,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Collapse,
	TableHead,
	tableCellClasses,
} from "@mui/material";
import DownloadIcon from "../assets/pngs/downloadIcon.png";
import { styled } from "@mui/material/styles";

const StyledTabButton = styled(Button)(({ isSelected, isSearch }) => ({
	height: "44px",
	width: "136px",
	backgroundColor: isSelected ? "#025131" : "transparent",
	fontFamily: "Inter",
	fontWeight: "bold",
	fontSize: "20px",
	color: isSelected ? "#fff" : "#303030",
	letterSpacing: 0,
	border: isSelected
		? "1px solid #025131"
		: isSearch
		? "1px solid #000"
		: "none",
	borderRadius: "4px",
	"&:hover": {
		backgroundColor: isSelected ? "#025131" : "#F2F2F2",
		color: isSelected ? "#fff" : "#000",
	},
	textTransform: "none",
}));

const StyledHeaderCell = styled(TableCell)(() => ({
	backgroundColor: "#F2F2F2",
	fontWeight: "bold",
	fontSize: "20px",
	fontFamily: "Inter",
	color: "#303030",
	border: "1px solid #303030",
	height: "34px",
	padding: "10px 20px",
}));

const StyledBodyCell = styled(TableCell)(() => ({
	backgroundColor: "#FFFFFF",
	fontWeight: "light",
	fontSize: "16px",
	fontFamily: "Inter",
	color: "#038C5A",
	border: "1px solid #303030",
	height: "25px",
	padding: "7px 20px",
}));

const StyledNestedBodyCell = styled(TableCell)(() => ({
	backgroundColor: "#FFFFFF",
	fontWeight: "light",
	fontSize: "16px",
	fontFamily: "Inter",
	color: "#038C5A",
	height: "25px",
	padding: "7px 20px",
}));

const NestedRow = ({ columns, row, index }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow
				key={index}
				hover
				tabIndex={-1}
				onClick={() => setOpen(!open)}
				style={{ cursor: "pointer" }}
			>
				{columns.map((column) => {
					let counter = index + 1;
					let value =
						row[column.id] === null || row[column.id] === undefined
							? "---"
							: row[column.id];

					if (column.id === "tableOfContent") {
						value = (
							<>
								<Typography fontWeight={"bold"} display="inline">
									{counter} -
								</Typography>

								<Typography display="inline">&nbsp;{value}</Typography>
							</>
						);
					}

					return (
						<StyledBodyCell key={column.id} align={column.align}>
							{typeof value != "undefined" ? value : counter}
						</StyledBodyCell>
					);
				})}
			</TableRow>

			{open && (
				<TableRow>
					<TableCell style={{ padding: 0 }} colSpan={columns?.length}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Table
								sx={{
									[`& .${tableCellClasses.root}`]: {
										borderTop: "none",
										borderBottom: "none",
										borderRight: "none",
									},
								}}
							>
								<TableBody>
									{row?.documents?.map((document, index) => {
										return (
											<>
												<TableRow>
													<StyledNestedBodyCell align="left">
														<Typography fontWeight={"bold"} display="inline">
															{document?.name}
														</Typography>
													</StyledNestedBodyCell>

													<StyledNestedBodyCell
														align="center"
														style={{ width: 150 }}
													>
														{document?.dueDate}
													</StyledNestedBodyCell>

													<StyledNestedBodyCell
														align="center"
														style={{ width: 150 }}
													>
														{document?.percentageComplete}
													</StyledNestedBodyCell>
												</TableRow>
											</>
										);
									})}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

const NestedTable = ({ columns, records }) => {
	return (
		<TableContainer
			sx={{
				borderRadius: "4px",
				border: "1px solid #000",
				marginBottom: "20px",
			}}
		>
			<Table
				sx={{
					borderCollapse: "collapse",
					borderStyle: "hidden",
					"& td": {
						border: "1px solid #626262",
					},
				}}
			>
				<TableHead>
					<TableRow>
						{columns.map((column) => (
							<StyledHeaderCell
								key={column.id}
								align={column.align}
								style={{ width: column.width }}
							>
								{column.label}
							</StyledHeaderCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{records &&
						records.length > 0 &&
						records
							// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								return (
									<NestedRow
										columns={columns}
										row={row}
										key={index}
										index={index}
									/>
								);
							})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const Documents = () => {
	const DHF_COLUMNS = [
		{
			id: "tableOfContent",
			label: "DHF Table of Content",
			align: "left",
		},
		{
			id: "dueDate",
			label: "Due Date",
			width: 150,
			align: "center",
		},
		{
			id: "percentageComplete",
			label: "% Complete",
			width: 150,
			align: "center",
		},
	];

	const records = [
		{
			tableOfContent: "Design Planning",
			dueDate: "30 SEP 2023",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 1",
					dueDate: "20 SEP 2024",
					percentageComplete: "15.0%",
				},
			],
		},
		{
			tableOfContent: "Design Input",
			dueDate: "12 OCT 2023",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 2",
					dueDate: "18 OCT 2024",
					percentageComplete: "10.0%",
				},
			],
		},
		{
			tableOfContent: "Risk Management",
			dueDate: "07 FEB 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 3",
					dueDate: "25 FEB 2024",
					percentageComplete: "5.0%",
				},
			],
		},
		{
			tableOfContent: "Usability Engineering",
			dueDate: "12 JUN 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 4",
					dueDate: "30 JUN 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Product Specification",
			dueDate: "26 MAR 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 5",
					dueDate: "01 APR 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Verification",
			dueDate: "30 JUN 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 6",
					dueDate: "05 JUL 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Validation",
			dueDate: "30 JUN 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 7",
					dueDate: "10 JUL 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Design Review",
			dueDate: "26 MAR 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 8",
					dueDate: "15 APR 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Design Changes",
			dueDate: "30 JUN 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 9",
					dueDate: "01 AUG 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Regulatory Information",
			dueDate: "26 OCT 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 10",
					dueDate: "15 NOV 2024",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Device Lifecycle",
			dueDate: "24 DEC 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 11",
					dueDate: "01 JAN 2025",
					percentageComplete: "0.0%",
				},
			],
		},
		{
			tableOfContent: "Device Master Record",
			dueDate: "24 DEC 2024",
			percentageComplete: "0.0%",
			documents: [
				{
					name: "Document 12",
					dueDate: "05 JAN 2025",
					percentageComplete: "0.0%",
				},
			],
		},
	];

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				padding: "3rem 3rem",
				gap: "20px",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0rem 2.5rem",
				}}
			>
				<Box sx={{ display: "flex" }}>
					<Typography
						fontWeight={"bold"}
						fontFamily={"Inter"}
						fontSize={"20px"}
						color={"#303030"}
						display="inline"
					>
						Documents -&nbsp;
					</Typography>

					<Typography
						fontWeight={"bold"}
						fontFamily={"Inter"}
						fontSize={"20px"}
						color={"#038C5A"}
						display="inline"
					>
						Tissue Containment
					</Typography>
				</Box>

				<img src={DownloadIcon} alt="" width={"39px"} height={"39px"} />
			</Box>

			<Box
				sx={{
					display: "flex",
					gap: "40px",
					padding: "0rem 4rem",
				}}
			>
				<StyledTabButton isSelected>DHF View</StyledTabButton>
				<StyledTabButton>eStar View</StyledTabButton>
				<StyledTabButton isSearch>Search</StyledTabButton>
			</Box>

			<Box
				sx={{
					display: "flex",
					padding: "0rem 4rem",
				}}
			>
				<NestedTable columns={DHF_COLUMNS} records={records} />
			</Box>
		</Box>
	);
};

export default Documents;
