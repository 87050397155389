import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from "./services/interceptor";

import MainLayout from './layouts/MainLayout';
import Explore from './views/Explore';
import TracerDecision from './views/TracerDecision';
import TracerSection from './views/TracerSection';
import LoginPage from './components/Auth/Login';
import SankeyDiagram from './components/FinancialGraph/FinancialGraph';
import GraphTree from './components/GraphTree/GraphTreeDemo';
import Documents from './views/Documents';
import Summary from './components/explore/Summary';

import StoreProvider from './store/StoreProvider';

function App() {
  const [activeView, setActiveView] = useState("requirements");
  const [dashedLines, setDashedLines] = useState(false);
  const openModal = () => { /* Modal opening logic */ };

  return (
    <StoreProvider>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/Explore" element={<MainLayout><Explore /></MainLayout>} />
            <Route path="/Summary" element={<MainLayout><Summary /></MainLayout>} />
            <Route path="/Tracer" element={<MainLayout><TracerDecision /></MainLayout>} />
            {/* <Route path="/Sankey" element={<SankeyDiagram />} /> */}
            {/* <Route path="/GraphTree" element={<MainLayout><GraphTree /></MainLayout>} /> */}
            <Route
              path="/Tracer-Requirements"
              element={
                <MainLayout>
                  <TracerSection
                    activeView={activeView}
                    dashedLines={dashedLines}
                    openModal={openModal}
                  />
                </MainLayout>
              }
            />
            <Route path="/Documents" element={<MainLayout><Documents /></MainLayout>} />
          </Routes>
        </Router>
      </ApolloProvider>
    </StoreProvider>
  );
}

export default App;
