import React, {useLayoutEffect, useState, useRef, useEffect} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenSharpIcon from '@mui/icons-material/CloseFullscreenSharp';
import {useLazyQuery} from "@apollo/client";
import {TREE_GRAPH} from "../../gql/queries/graphs";
import {CircularProgress} from "@mui/material";

const GraphTree = ({ initialGraphId, isFullScreen, onExpandClick }) => {
  const [isExpanded, setIsExpanded] = useState(isFullScreen || false);
  const chartDivRef = useRef(null);
  const initialNodeId = initialGraphId;
  const existingNodes = useRef(new Set());
  const chartDataRef = useRef([]);
  const nodePositions = useRef({});
  const expandedNodes = useRef(new Set());

  const [getTreeGraph, { loading: isGraphLoading }] = useLazyQuery(TREE_GRAPH,{fetchPolicy: "no-cache"});

  const fetchGraphData =  (id) => {
    return getTreeGraph({
      variables: { ids: id },
    }).then(response=>{
      const limitedNodes = response.data.graph.nodes.slice(0, 10);
      const limitedLinks = response.data.graph.links.slice(0, 10);

      return Promise.resolve({ nodes: limitedNodes, links: limitedLinks });
    }).catch((error)=>{
      console.error("Error fetching data:", error);
      return Promise.reject({ nodes: [], links: [] });
    })
  };

  const generateFixedPosition = (id, isRoot = false) => {
    if (isRoot) {
      return { x: am4core.percent(50), y: am4core.percent(50) };
    }

    const spacing = 15;
    const maxAttempts = 100;
    let attempts = 0;
    let x, y, isCollision;

    do {
      x = Math.random() * 80 + 10;
      y = Math.random() * 80 + 10;

      isCollision = Object.values(nodePositions.current).some(
        (pos) => Math.abs(pos.x - x) < spacing && Math.abs(pos.y - y) < spacing
      );

      attempts++;
    } while (isCollision && attempts < maxAttempts);

    nodePositions.current[id] = { x, y };
    return nodePositions.current[id];
  };

  const getDescendantIds = (nodeId) => {
    const directChildren = chartDataRef.current
      .filter((node) => node.linkWith && node.linkWith.includes(nodeId))
      .map((node) => node.id);

    return directChildren.reduce((acc, childId) => {
      return acc.concat(childId, getDescendantIds(childId));
    }, []);
  };

  const loadData = (id, series) => {
    fetchGraphData(id)
        .then(graphData=>{
          const newNodes = [];
          const newLinks = [];

          graphData.nodes.forEach((node) => {
            if (!existingNodes.current.has(node.id)) {
              existingNodes.current.add(node.id);
              const isRoot = node.id === initialNodeId;
              const position = generateFixedPosition(node.id, isRoot);

              newNodes.push({
                id: node.id,
                name: node.properties.name || node.properties.device_name,
                type: node.type,
                color: node.type === "ProductCode" ? am4core.color("#91abc0") : am4core.color("#dcd4fb"),
                fixed: isRoot,
                x: position.x,
                y: position.y,
                linkWith: [],
              });
            }
          });

          graphData.links.forEach((link) => {
            if (existingNodes.current.has(link.source) && existingNodes.current.has(link.target)) {
              newLinks.push({
                id: link.source,
                linkWith: link.target,
              });
            }
          });

          chartDataRef.current = [...chartDataRef.current, ...newNodes];
          series.data = chartDataRef.current;

          newLinks.forEach((link) => {
            const sourceNode = series.data.find((node) => node.id === link.id);
            if (sourceNode && !sourceNode.linkWith.includes(link.linkWith)) {
              sourceNode.linkWith = [...(sourceNode.linkWith || []), link.linkWith];
            }
          });
        }).catch(_=>{
          console.warn("Graph data is empty or undefined")
    })
  };

  useEffect(() => {
    const chart = am4core.create(chartDivRef.current, am4plugins_forceDirected.ForceDirectedTree);
    chart.background.fill = am4core.color("#000000");
    chart.logo.disabled = true;

    const series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());

    series.dataFields.id = "id";
    series.dataFields.name = "name";
    series.dataFields.linkWith = "linkWith";
    series.dataFields.x = "x";
    series.dataFields.y = "y";
    series.dataFields.fixed = "fixed";

    series.interpolationDuration = 0; // Disable animations
    series.nodes.template.draggable = false; // Make nodes non-draggable
    series.maxRadius = isExpanded ? 18 : 10;
    series.minRadius = isExpanded ? 18 : 5;

    series.nodes.template.tooltipText = "{name}";
    series.nodes.template.circle.radius = isExpanded ? 8 : 1;
    series.nodes.template.fillOpacity = 1;
    series.fontSize = isExpanded ? 6 : 2;
    series.nodes.template.label.text = "{name}";
    series.nodes.template.label.hideOversized = true;
    series.nodes.template.label.truncate = true;
    series.nodes.template.label.fill = am4core.color("#000000");
    series.links.template.stroke = am4core.color("#A9A9A9");
    series.links.template.strokeOpacity = 1;

    series.nodes.template.adapter.add("fill", (fill, target) => {
      return target.dataItem.dataContext.color || am4core.color("#dcd4fb");
    });

    series.nodes.template.adapter.add("stroke", (stroke, target) => {
      return target.dataItem.dataContext.type === "Device"
        ? am4core.color("#88c5e1")
        : am4core.color("#dcd4fb");
    });

    loadData(initialNodeId, series);

    series.nodes.template.events.on("hit", async (event) => {
      const targetNode = event.target.dataItem;
      const targetNodeId = targetNode.dataContext.id;

      if (expandedNodes.current.has(targetNodeId)) {
        const descendants = getDescendantIds(targetNodeId);

        chartDataRef.current = chartDataRef.current.filter(
          (node) => !descendants.includes(node.id) || node.id === targetNodeId
        );

        existingNodes.current = new Set(chartDataRef.current.map((node) => node.id));
        series.data = chartDataRef.current;
        expandedNodes.current.delete(targetNodeId);
      }
      else {
        await loadData(targetNodeId, series);
        expandedNodes.current.add(targetNodeId);
      }
    });

    return () => {
      chart.dispose();
    };
  }, [isExpanded, initialGraphId]);

  const handleExpandClick = () => {
    setIsExpanded((prev) => !prev);
    if (onExpandClick) onExpandClick(!isExpanded);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: isExpanded ? "100%" : "195px",
        backgroundColor: "#000",
        transition: "width 0.3s, height 0.3s",
      }}
    >
      {
        isGraphLoading && (
              <CircularProgress/>
          )
      }

      <div
          ref={chartDivRef}
          id="chartdiv"
          style={{
            width: "100%",
            height: "100%",
            display: isGraphLoading && "none"
          }}
      />

      <button
          onClick={handleExpandClick}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "transparent",
    border: "none",
    color: "#fff",
    cursor: "pointer",
    fontSize: "20px",
  }}
>
  {isExpanded ? <CloseFullscreenSharpIcon /> : <OpenInFullOutlinedIcon />}
</button>

    </div>
  );
};

export default GraphTree;
